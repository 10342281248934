import React from "react"
import * as styles from "./styles.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default ({ name, icon, click }) => {
  return (
    <button type="button" className={styles.button} onClick={e => click(e)}>
      <span>
        <span>{name}</span>
        {icon && <FontAwesomeIcon icon={icon} size="lg" />}
      </span>
    </button>
  )
}
