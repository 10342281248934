import React, { Fragment } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { STRIPE_KEY } from "../utils/helpers"
import Helmet from "../components/Helmet"
import One from "../signupPage/One"
import Two from "../signupPage/Two"
import Three from "../signupPage/Three"
import Footer from "../components/Footer"

const stripePromise = loadStripe(STRIPE_KEY)

const SignupPage = () => {
  return (
    <Fragment>
      <Helmet
        title="Subscribe for Access to the Daily ETF Stock Report Service"
        descr="ETF Stock Report provides a daily breakdown of the top thirty-five stocks, in various portfolio sizes, performing against the major market indexes. Learn how to subscribe to the ETF Stock Report."
        slug="/signup"
      />
      <One />
      <Two />
      <Three stripePromise={stripePromise} />
      <Footer />
    </Fragment>
  )
}

export default SignupPage
