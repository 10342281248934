import React, { useState, useRef, Fragment } from "react"
import { navigate } from "gatsby"
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
import {
  API_URL,
  SUBSCRIPTIONS,
  fmtCurrency,
  getVal,
  createMarkup,
} from "../../utils/helpers"
import Input from "../../components/Input"
import InputStripe from "../../components/InputStripe"
import Radios from "../../components/Radios"
import Msg from "../../components/Msg"
import Submit from "../../components/Submit"
import Button from "../../components/Button"
import Alert from "../../components/Alert"
import * as styles from "./styles.module.css"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

function fmtSubscription(subscription) {
  return subscription === "individual" ? "Individual" : "Single Advisor"
}

const Three = () => {
  const [names, setNames] = useState({
    subscription: "individual",
    promo: "",
  })
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})
  const [apply, setApply] = useState("")
  const [alert] = useState({})

  const formElement = useRef(null)
  const stripe = useStripe()
  const elements = useElements()

  const handleUpdate = (name, value) => {
    if (name === "optin") {
      setNames(names => ({ ...names, [name]: !value }))
    } else {
      setNames(names => ({ ...names, [name]: value ? value : "" }))
    }
  }

  /* TODO
    init to bring in SUBSCRIPTIONS from server
    {
      value: "individual",
      name: "Individual",
      price: "$30 per month",
      amt: 3000,
    },
    {
      value: "advisor",
      name: "Single Advisor",
      price: "$45 per month",
      amt: 4500,
    },
  */

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/signup`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      data.append(key, value)
    })
    //data.append("amt", getVal(SUBSCRIPTIONS, names.subscription, "amt"))

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      handleResponse(json, null)
    } catch (error) {
      handleResponse(null, error)
    }
  }

  const handleResponse = (json, error) => {
    if (json) {
      if (json.resp === 1) {
        //handlePayment(json.stripeCustomerId, json.clientSecret)
        handlePayment(json.stripeCustomerId)
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } else {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handlePayment = async stripeCustomerId => {
    try {
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${names.fname} ${names.lname}`,
          email: `${names.email}`,
        },
      })

      if (result.error) {
        setMsg({
          type: "error",
          text: result.error.message,
        })
      } else {
        handleSuccess({
          customerId: stripeCustomerId,
          paymentMethodId: result.paymentMethod.id,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "Please enter a payment method.",
      })
      setErrors(["card"])
    }
  }

  const handleSuccess = async stripeResponse => {
    const url = new URL(`${API_URL}/submit`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => data.append(key, value))
    data.append("stripeResponse", JSON.stringify(stripeResponse))

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        navigate("/receipt", {
          state: {
            id: json.id,
          },
        })

        /*
          maybe need to handle scenario where stripe subscription fails
        */
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  const handlePromo = e => {
    e.preventDefault()
    setApply("applying")

    if (names.promo.toUpperCase() === "FLORIDASTRONG") {
      setApply("applied")
    } else {
      setApply("error")
    }
  }

  return (
    <section className={styles.three}>
      {alert.type === "working" ? (
        <Alert data={alert} />
      ) : (
        <form
          method="post"
          action="/"
          onSubmit={e => handleSubmit(e)}
          ref={formElement}
        >
          <p>
            For less than the daily Wall Street Journal or cup of coffee,
            Individuals can use our model to day trade. Advisors can use our
            model to strategically position themselves as the market changes.
            The world is not perfect, but your portfolio can be.
          </p>

          <div className="one">
            <Radios
              name="subscription"
              label="Subscription"
              reqd={true}
              click={handleUpdate}
              data={SUBSCRIPTIONS.map(item => {
                return {
                  value: item.value,
                  name: item.name,
                  price: item.price,
                  amt: item.amt,
                  checked: names.subscription === item.value ? true : false,
                }
              })}
            />
          </div>

          {!names.subscription ? (
            <Alert
              data={{
                type: "notice",
                text: "Please select a subscription to continue.",
              }}
            />
          ) : (
            <Fragment>
              {apply !== "applied" && (
                <div className="promo">
                  <Input
                    name="promo"
                    label="Optional Promo Code"
                    reqd={false}
                    autocomplete="off"
                    update={handleUpdate}
                    errors={errors}
                  />

                  <div style={{ marginTop: "15px" }}>
                    {apply === "applying" ? (
                      <Msg data={{ type: "working", text: "" }} />
                    ) : apply === "error" ? (
                      <Fragment>
                        <Msg
                          data={{ type: "error", text: "Promo code not valid" }}
                        />
                        <Button
                          name="Apply"
                          icon={faChevronCircleRight}
                          click={handlePromo}
                        />
                      </Fragment>
                    ) : (
                      <Button
                        name="Apply"
                        icon={faChevronCircleRight}
                        click={handlePromo}
                      />
                    )}
                  </div>
                </div>
              )}

              <div className="two">
                <Input
                  name="fname"
                  label="First Name"
                  reqd={true}
                  autocomplete="given-name"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="lname"
                  label="Last Name"
                  reqd={true}
                  autocomplete="family-name"
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div className="three">
                <Input
                  name="email"
                  label="Email Address"
                  reqd={true}
                  autocomplete="email"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="confirm"
                  label="Confirm Email Address"
                  reqd={true}
                  autocomplete="email"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="phone"
                  label="Phone"
                  reqd={true}
                  autocomplete="tel"
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div className="one">
                <Input
                  name="address"
                  label="Address"
                  reqd={true}
                  autocomplete="street-address"
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div className="three">
                <Input
                  name="city"
                  label="City"
                  reqd={true}
                  autocomplete="address-level2"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="st"
                  label="State (2-Letter)"
                  reqd={true}
                  autocomplete="address-level1"
                  update={handleUpdate}
                  errors={errors}
                />

                <Input
                  name="zip"
                  label="Zip / Postal Code"
                  reqd={true}
                  autocomplete="postal-code"
                  update={handleUpdate}
                  errors={errors}
                />
              </div>

              <div className="one" style={{ marginTop: "25px" }}>
                <table>
                  <thead>
                    <tr>
                      <th>Qty</th>
                      <th className="left">Item</th>
                      <th className="price">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="center">1</td>
                      <td className="left">
                        <div className={styles.item}>
                          <span>
                            {names.fname && names.lname
                              ? `ETFSR ${fmtSubscription(
                                  names.subscription
                                )} Subscription for ${names.fname} ${
                                  names.lname
                                }`
                              : `ETFSR ${fmtSubscription(
                                  names.subscription
                                )} Subscription`}
                          </span>
                          <small>
                            {names.email
                              ? `Account accessed with ${names.email}`
                              : `Account accessed with email address`}
                          </small>
                        </div>
                      </td>
                      <td className="right">
                        {fmtCurrency(
                          getVal(SUBSCRIPTIONS, names.subscription, "amt") / 100
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="right" colSpan="2">
                        RECURRING AMOUNT:
                      </td>
                      <td className="right">
                        {getVal(SUBSCRIPTIONS, names.subscription, "price")}
                      </td>
                    </tr>

                    {apply === "applied" && (
                      <tr>
                        <td className="right" colSpan="2">
                          PROMO CODE APPLIED:
                        </td>
                        <td className="right">
                          (
                          {fmtCurrency(
                            getVal(SUBSCRIPTIONS, names.subscription, "amt") /
                              100
                          )}
                          )
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th className="right" colSpan="2">
                        TOTAL CHARGED TODAY:{" "}
                        {/*<br />
                        <small style={{ fontWeight: "normal" }}>
                          *after two weeks you will be charged{" "}
                          {fmtCurrency(
                            getVal(SUBSCRIPTIONS, names.subscription, "amt") /
                              100
                          )}{" "}
                          per month
                        </small>*/}
                      </th>
                      <td className="right">
                        {apply === "applied"
                          ? "$0.00"
                          : fmtCurrency(
                              getVal(SUBSCRIPTIONS, names.subscription, "amt") /
                                100
                            )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="one">
                <InputStripe
                  name="card"
                  label="Credit or Debit Card"
                  reqd={true}
                  errors={errors}
                />
              </div>

              <div className={styles.terms}>
                <p className="hdg">
                  Verify your subscription details and click submit below to
                  process your payment.
                </p>

                <p
                  dangerouslySetInnerHTML={createMarkup(`All credit card charges will show up under the title of <strong>“ETF Stock Report”</strong> on your credit card statement.
                  Clicking the submit payment button below will process your
                  subscription and your card will be charged ${fmtCurrency(
                    0 / 100
                  )}. After two weeks your card will be charged ${fmtCurrency(
                    getVal(SUBSCRIPTIONS, names.subscription, "amt") / 100
                  )} per month.`)}
                />
              </div>

              <div className="msgSubmit">
                {msg.type && <Msg data={msg} />}

                <div className={msg.type === "working" ? "hidden" : ""}>
                  <Submit
                    name="Submit Payment & Subscribe"
                    icon={faChevronCircleRight}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </form>
      )}
    </section>
  )
}

const Payment = ({ stripePromise }) => {
  return (
    <Elements stripe={stripePromise}>
      <Three />
    </Elements>
  )
}

export default Payment
