import React, { Fragment } from "react"
import { Link } from "gatsby"
import logo from "../../img/logo.svg"
import * as styles from "./styles.module.css"

const One = () => {
  const items = [
    {
      id: "signin",
      name: "Sign In",
      btn: true,
    },
  ]

  return (
    <Fragment>
      <div className={styles.containerDesktop}>
        <div className={styles.left}>
          <Link to="/" className={styles.logo}>
            <img src={logo} alt="ETF Stock Report Logo" />
          </Link>
        </div>

        <div className={styles.right}>
          {items.map(item => {
            return item.btn ? (
              <Link
                to={`/${item.id}`}
                className={item.id === "signup" ? `btn green` : `btn`}
                key={item.id}
              >
                {item.name}
              </Link>
            ) : null
          })}
        </div>
      </div>
    </Fragment>
  )
}

export default One
